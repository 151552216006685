import { UsuarioDto } from '@/models/dto/usuario.dto';
import { LoadingService } from '@/services/loading.service';
import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { UsuariosService } from '@/services/usuarios.service';
import { validateFieldsUsuario } from '@/utils/validateFields/validateFieldsUsuario';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-usuarios-upsert',
  templateUrl: './usuarios-upsert.component.html',
  styleUrls: ['./usuarios-upsert.component.scss'],
})
export class UsuariosUpsertComponent implements OnInit {
  id: string;
  usuarioDto = new UsuarioDto();
  auxUsuarioDto = new UsuarioDto();
  constructor(
    private sharedService: SharedService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private usuariosService: UsuariosService,
    private sweetAlertService: SweetAlert2Service
  ) {}

  ngOnInit(): void {
    this.sharedService.changeAuth(true);
    this.route.queryParams.subscribe((params) => {
      this.id = params['id'];
      if (this.id) {
        this.findById(+this.id);
      }
    });
  }

  findById(id: number) {
    this.usuariosService.findOneUsuario(id).subscribe((result) => {
      this.usuarioDto = result;
    });
  }

  cadastrarUsuario() {
    delete this.usuarioDto['perfil'];
    const validate = validateFieldsUsuario(this.usuarioDto);
    if (!validate.isValid) {
      this.sweetAlertService.info(
        'Existe campos invalidos ou vazios, revise o formulario por favor!'
      );
      return;
    }
    this.usuariosService.createUsuario(this.usuarioDto).subscribe(
      (res) => {
        this.sweetAlertService.success('Usuário criado com sucesso!');
        this.router.navigate(['administracao/usuarios/lista']);
      },
      (err) => {
        this.sweetAlertService.error(err.message);
      }
    );
  }
  editarUsuario() {
    delete this.usuarioDto['id'];
    delete this.usuarioDto['createdAt'];
    delete this.usuarioDto['updatedAt'];
    delete this.usuarioDto['perfil'];
    const validate = validateFieldsUsuario(this.usuarioDto);
    if (!validate.isValid) {
      return this.sweetAlertService.info(
        'Existe campos invalidos ou vazios, revise o formulario por favor!'
      );
    }
    this.auxUsuarioDto.senha
      ? (this.usuarioDto.senha = this.auxUsuarioDto.senha)
      : null;

    this.usuariosService.updateUsuario(+this.id, this.usuarioDto).subscribe(
      (res) => {
        this.sweetAlertService.success('Usuário editado com sucesso!');
        this.router.navigate(['administracao/usuarios/lista']);
      },
      (err) => {
        this.sweetAlertService.error(err.message);
      }
    );
  }
  cancelarCadastro() {
    this.router.navigate(['administracao/usuarios/lista']);
  }
}
