import { SweetAlert2Service } from '@/services/sweet-alert2.service';
import { TerminaisMensagensService } from '@/services/terminais-mensagens.service';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as dayjs from 'dayjs';
import { Editor, Toolbar } from 'ngx-editor';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-create-mensagem',
  templateUrl: './create-mensagem.component.html',
  styleUrls: ['./create-mensagem.component.scss']
})
export class CreateMensagemComponent implements OnInit{
  @Output()
  created: EventEmitter<any> = new EventEmitter()
  @Input() inputsValue: any;

  editor: Editor
  toolbar: Toolbar = [
    ['bold'],
    ['align_left', 'align_center', 'align_right'],
  ]

  selection = new SelectionModel<any>(true, []);
  columns: string[] = ['select', 'ponto_venda', 'pontovenda.status', 'serial', 'modelo', 'status', 'status_mensagem']
  form!: FormGroup
  status = [{name:'Inativo', value: 'INATIVO'}, {name:'Ativo', value: 'ATIVO'}]
  selectedItems: any[] = []
  terminais: any[] = []
  terminaisFiltrados: any[] = []
  formTableFilter!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private swal2: SweetAlert2Service,
    private terminaisMensagensService: TerminaisMensagensService,
  ) {}

  ngOnInit() {
    this.editor = new Editor();
    this.form = this.formBuilder.group({
      periodo_inicio: [this.getCurrentDateTime(), [Validators.required]],
      periodo_fim: [this.getCurrentDateTime(), [Validators.required]],
      status: [this.status[0]],
      descricao: ['', [Validators.required]],
      mensagem: ['', [Validators.required]],
      id: null
    })

    this.formTableFilter = this.formBuilder.group({
      search: [''],
    })
    let interval = setTimeout(() => null);
    this.formTableFilter.valueChanges.subscribe(() => {
      clearInterval(interval)
      interval = setTimeout(() => {
        this.filtraTerminais()
      }, 500)
    })
}

  resetForm(){
    this.form.patchValue({
      periodo_inicio : this.getCurrentDateTime(),
      periodo_fim: this.getCurrentDateTime(),
      descricao: '',
      mensagem: '',
      id: null
    })
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  async ngOnChanges(changes: SimpleChanges) {
    await this.fetchTerminais();
    if (changes['inputsValue'].firstChange === false) {
      let {periodo_inicio, periodo_fim, status, descricao, mensagem, id} = changes['inputsValue'].currentValue
      const currentStatus = this.status.find((obj) => obj.value == status)

      this.fetchTerminaisSelecionados(id)
      this.selection.clear();
      this.form.reset();

      this.form.patchValue({
        periodo_inicio : dayjs(periodo_inicio).format('YYYY-MM-DDTHH:mm'),
        periodo_fim: dayjs(periodo_fim).format('YYYY-MM-DDTHH:mm'),
        status: currentStatus,
        descricao,
        mensagem,
        id
      })
    }
  }

  async fetchTerminais(){
    const terminais = await this.terminaisMensagensService.findAllTerminais()
    this.terminais = terminais
    this.terminaisFiltrados = terminais
  }

  async fetchTerminaisSelecionados(id: number){
    const data = await this.terminaisMensagensService.findTerminaisSelecionados(id)
    const idsTerminais = data.map((result) => result.tme_terminal_id);
    let selectedTerminais = this.terminais.filter(terminal => idsTerminais.includes(terminal.id))
    selectedTerminais.forEach(selectedTerminal => {
      const selectedId = selectedTerminal.id;
      const matchingTerminal = data.find(terminal => terminal.tme_terminal_id === selectedId);
      if (matchingTerminal) {
        selectedTerminal.status_mensagem = matchingTerminal.tme_status
      }
    });

    this.selection.select(...selectedTerminais)
  }

  filtraTerminais() {
    const search = this.formTableFilter.value['search'] || '';
    if(search === ''){
      this.terminaisFiltrados = this.terminais
      return
    }
    const normalizeSearch = search
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");

    const match = new RegExp(`^${normalizeSearch}`, "i")
    this.terminaisFiltrados = this.terminais
      .filter(item => {
        let matched = false;
        matched = match.test(String(item.ponto_venda_id)) || match.test(item.pontovenda.nome_comercial) || match.test(item.serial)

        return matched
      })

  }

  async sendForm() {
    const periodo_inicio = dayjs(this.form.value['periodo_inicio'])
    const periodo_fim = dayjs(this.form.value['periodo_fim'])
    const mensagem = {
      periodo_inicio,
      periodo_fim,
      descricao: this.form.value['descricao'],
      mensagem: this.form.value['mensagem'],
      status: this.form.value['status']?.value,
    }

    const selectedTerminais = this.selection.selected
    const id = this.form.value['id']
    this.created.emit({mensagem, selectedTerminais, id});

    this.resetForm()
    this.selection.clear()

    this.swal2.success('Mensagem criada!');
    this.fetchTerminais();
  }

  getCurrentDateTime(): string {
    return dayjs().format('YYYY-MM-DDTHH:mm');
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.terminaisFiltrados.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.terminaisFiltrados);
  }
}
