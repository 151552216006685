<div class="perfis">
  <div class="header">
    <h1>Controle de Acesso do Usuário</h1>
    <button mat-mini-fab color="primary" (click)="add()">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <div class="materialTableHeader d-flex">
    <h3>Perfis</h3>
  </div>
  <table mat-table [dataSource]="perfis">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let perfil"> {{perfil.id}} </td>
    </ng-container>

    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef>Nome</th>
      <td mat-cell *matCellDef="let perfil"> {{perfil.nome}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let perfil"> {{perfil.status}} </td>
    </ng-container>

    <ng-container matColumnDef="totalPermissoes">
      <th mat-header-cell *matHeaderCellDef>Permissões</th>
      <td mat-cell *matCellDef="let perfil"> {{perfil.totalPermissoes}} </td>
    </ng-container>

    <ng-container matColumnDef="acoes">
      <th mat-header-cell *matHeaderCellDef>Ações</th>
      <td mat-cell *matCellDef="let perfil">

        <button
          mat-icon-button
          class="small-icon-button"
          matTooltip="Vincular perissões"
          (click)="showPermissions(perfil.id)"
        >
          <mat-icon>attachment</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
  </table>
</div>
