import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuAdministracaoComponent } from './menu-administracao.component';
import { UsuariosListComponent } from './usuarios/usuarios-list.component';
import { UsuariosUpsertComponent } from './usuarios/usuarios-upsert/usuarios-upsert.component';
import { AtualizacaoTerminalComponent } from './atualizacao-terminal/atualizacao-terminal.component';
import { PagamentosComponent } from '../pagamentos/pagamentos.component';
import { ComissoesPadroesListComponent } from './comissoes-padroes/comissoes-padroes-list.component';
import { ComissoesPadroesUpsertComponent } from './comissoes-padroes/comissoes-padroes-upsert/comissoes-padroes-upsert.component';
import { TerminaisFirmwareComponent } from './terminais-firmware/terminais-firmware.component';
import { DiasFaturamentoComponent } from './dias-faturamento/dias-faturamento.component';
import { EstornarPixComponent } from './estornar-pix/estornar-pix.component';
import { AlteraStatusRecargaComponent } from './altera-status-recarga/altera-status-recarga.component';
import { PerfisComponent } from './perfis/perfis.component';
import { TrocarSenhaComponent } from './trocar-senha/trocar-senha.component';
import { MensagensTerminaisComponent } from './mensagens-terminais/mensagens-terminais.component';
import { FeriadosComponent } from './feriados/feriados.component';
import { AdministracaoRoutingModule } from './administracao-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatRadioModule } from '@angular/material/radio';
import { ResultadoQuitacaoComponent } from '../pagamentos/components/resultado-quitacao/resultado-quitacao.component';
import { CreateTerminalFirmwareComponent } from './terminais-firmware/components/create-terminal-firmware/create-terminal-firmware.component';
import { CreateMensagemComponent } from './mensagens-terminais/components/create-mensagem/create-mensagem.component';
import { NgxEditorModule } from 'ngx-editor';
import { MatExpansionModule } from '@angular/material/expansion';
import { BancosPdvComponent } from './bancos-pdv/bancos-pdv.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';


@NgModule({
  declarations: [
    MenuAdministracaoComponent,
    UsuariosListComponent,
    UsuariosUpsertComponent,
    AtualizacaoTerminalComponent,
    PagamentosComponent,
    ComissoesPadroesListComponent,
    ComissoesPadroesUpsertComponent,
    TerminaisFirmwareComponent,
    DiasFaturamentoComponent,
    EstornarPixComponent,
    AlteraStatusRecargaComponent,
    PerfisComponent,
    TrocarSenhaComponent,
    MensagensTerminaisComponent,
    FeriadosComponent,
    ResultadoQuitacaoComponent,
    CreateTerminalFirmwareComponent,
    CreateMensagemComponent,
    BancosPdvComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AdministracaoRoutingModule,
    MatCardModule,
    MatGridListModule,
    MatToolbarModule,
    MatRadioModule,
    MatExpansionModule,
    NgxEditorModule,
    MatAutocompleteModule
  ],
  providers: []
})
export class AdministracaoModule {}
