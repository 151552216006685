<mat-card appearance="outlined" class="card">
  <mat-card-content>
    <form (submit)="sendForm()" [formGroup]="form">
      <div>
        <mat-form-field appearance="outline" class="datepicker">
          <mat-label>Data início</mat-label>
          <input type="datetime-local" matInput placeholder="Data Início" formControlName="periodo_inicio" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="datepicker">
          <mat-label>Data fim</mat-label>
          <input type="datetime-local" matInput placeholder="Data Fim" formControlName="periodo_fim" />
        </mat-form-field>
      </div>
      <div>
        <app-input-text
          class="descricao col-lg-12 col-md-12 col-sm-12 col-xs-12"
          label="Descrição"
          formControlName="descricao"
        ></app-input-text>

        <div class="mensagem col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
          <ngx-editor [editor]="editor" formControlName="mensagem" placeholder="Digite a mensagem aqui..."></ngx-editor>
        </div>
      </div>
    </form>

    <form [formGroup]="formTableFilter" class="form form__table">
      <div class="search">
        <mat-form-field appearance="outline">
          <mat-label>Pesquisar</mat-label>
          <mat-icon matSuffix>search</mat-icon>
          <input matInput type="text" placeholder="PDV ou Serial do Terminal" formControlName="search" />
        </mat-form-field>
      </div>
    </form>

    <div class="scroll-container">
      <div class="materialTableHeader">
        <h3>Terminais</h3>
      </div>
      <table mat-table [dataSource]="terminaisFiltrados">

        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? toggleAllRows() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          [aria-label]="checkboxLabel()"
                          color="primary">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)"
                          [aria-label]="checkboxLabel(row)"
                          color="primary">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="ponto_venda">
          <th mat-header-cell *matHeaderCellDef>Ponto de Venda</th>
          <td mat-cell *matCellDef="let element"> {{element.pontovenda.id + " - "+ element.pontovenda.nome_comercial}} </td>
        </ng-container>

        <ng-container matColumnDef="pontovenda.status">
          <th mat-header-cell *matHeaderCellDef>Status PDV</th>
          <td mat-cell *matCellDef="let element"> {{element.pontovenda.status}} </td>
        </ng-container>

        <ng-container matColumnDef="serial">
          <th mat-header-cell *matHeaderCellDef> Serial </th>
          <td mat-cell *matCellDef="let element"> {{element.serial}} </td>
        </ng-container>

        <ng-container matColumnDef="modelo">
          <th mat-header-cell *matHeaderCellDef>Moledo</th>
          <td mat-cell *matCellDef="let element"> {{element.modelo.nome}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status Terminal</th>
          <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>

        <ng-container matColumnDef="status_mensagem">
          <th mat-header-cell *matHeaderCellDef>Status Mensagem</th>
          <td mat-cell *matCellDef="let element"> {{element.status_mensagem}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"
            (click)="selection.toggle(row)">
        </tr>
      </table>
    </div>

    <div class="actions">
      <button type="submit" mat-flat-button color="primary" [disabled]="!this.selection.selected.length" (click)="sendForm()">Cadastrar</button>
    </div>
  </mat-card-content>
</mat-card>
