<div class="page">
  <h1>Mensagem para os Terminais</h1>
  <app-create-mensagem
    (created)="onCreated($event)"
    [inputsValue]="inputsValue"
  ></app-create-mensagem>

  <div class="materialTableHeader">
    <h3>Mensagens</h3>
  </div>
  <table mat-table [dataSource]="mensagens">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let element">{{element.id}}</td>
    </ng-container>

    <ng-container matColumnDef="data_hora">
      <th mat-header-cell *matHeaderCellDef>Período</th>
      <td mat-cell *matCellDef="let element">{{this.formataPerido(element)}}</td>
    </ng-container>

    <ng-container matColumnDef="descricao">
      <th mat-header-cell *matHeaderCellDef>Descrição</th>
      <td mat-cell *matCellDef="let element">{{element.descricao}}</td>
    </ng-container>

    <ng-container matColumnDef="numero_terminais">
      <th mat-header-cell *matHeaderCellDef>Número de Terminais</th>
      <td mat-cell *matCellDef="let element">{{element.numero_terminais}}</td>
    </ng-container>

    <ng-container matColumnDef="mensagens_recebidas">
      <th mat-header-cell *matHeaderCellDef>Mensagens Recebidas</th>
      <td mat-cell *matCellDef="let element">{{element.mensagens_recebidas}}</td>
    </ng-container>

    <ng-container matColumnDef="mensagens_nao_recebidas">
      <th mat-header-cell *matHeaderCellDef>Mensagens Não Recebidas</th>
      <td mat-cell *matCellDef="let element">{{element.mensagens_nao_recebidas}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">
        <mat-slide-toggle
        class="example-margin"
        color="primary"
        [matTooltip]="element.status === 'ATIVO' ? 'ATIVO' : 'INATIVO'"
        [checked]="element.status === 'ATIVO'"
        (toggleChange)="updateStatus(element)"
      >
      </mat-slide-toggle>
      </td>
    </ng-container>

    <ng-container matColumnDef="editar">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button (click)="edit(element)" mat-icon-button class="small-icon-button" color="primary" matTooltip="Editar" class="small-icon-button">
          <mat-icon
            role="img"
            aria-label="Delete"
            aria-hidden="true"
            data-mat-icon-type="font"
            >edit</mat-icon
          >
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>
  </table>
</div>
