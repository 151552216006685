import { Injectable } from '@angular/core';
import { BaseApi } from './base/base-api.service';
import { UsuarioPerfilPermissao, UsuarioPerfilPermissaoCount } from '../@types/usuario-perfil';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsuarioPerfilService extends BaseApi {

  findAll(): Promise<UsuarioPerfilPermissaoCount[]> {
    return lastValueFrom(this.get(`${this.url}/usuarios-perfil`))
  }

  findOne(id:number): Promise<UsuarioPerfilPermissao>{
    return lastValueFrom(this.get(`${this.url}/usuarios-perfil/${id}`))
  }

  updatePermissions(id: number, data: {permissao_ids: number[]}): Promise<UsuarioPerfilPermissao> {
    return lastValueFrom(this.put(`${this.url}/usuarios-perfil/${id}/permissoes`, data))
  }
}
