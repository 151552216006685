export class UsuarioDto {
  id?: number;
  usuario_perfil_id: number = 0;
  usuario_grupo_id: number = 1;
  nome: string ='';
  login: string = '';
  senha: string = '';
  status: string = '';
  perfil?: usuarioPerfil;
  acesso_financeiro: number = 1;
  createdAt?: string;
  updatedAt?: string;
  user_updated_id: number | null = null;
}

interface usuarioPerfil {
  id: number;
  nome: string;
  status: string;
}
