import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MenuAdministracaoComponent } from './menu-administracao.component';
import { UsuariosListComponent } from './usuarios/usuarios-list.component';
import { UsuariosUpsertComponent } from './usuarios/usuarios-upsert/usuarios-upsert.component';
import { AtualizacaoTerminalComponent } from './atualizacao-terminal/atualizacao-terminal.component';
import { PagamentosComponent } from '../pagamentos/pagamentos.component';
import { ComissoesPadroesListComponent } from './comissoes-padroes/comissoes-padroes-list.component';
import { ComissoesPadroesUpsertComponent } from './comissoes-padroes/comissoes-padroes-upsert/comissoes-padroes-upsert.component';
import { TerminaisFirmwareComponent } from './terminais-firmware/terminais-firmware.component';
import { DiasFaturamentoComponent } from './dias-faturamento/dias-faturamento.component';
import { EstornarPixComponent } from './estornar-pix/estornar-pix.component';
import { AlteraStatusRecargaComponent } from './altera-status-recarga/altera-status-recarga.component';
import { PerfisComponent } from './perfis/perfis.component';
import { TrocarSenhaComponent } from './trocar-senha/trocar-senha.component';
import { MensagensTerminaisComponent } from './mensagens-terminais/mensagens-terminais.component';
import { FeriadosComponent } from './feriados/feriados.component';
import { BancosPdvComponent } from './bancos-pdv/bancos-pdv.component';
import { Permissao } from '@/enums/Permissao';
import { AclGuard } from 'src/app/guards/acl.guard';
import { HipercapProdutosComponent } from './hipercap-produtos/hipercap-produtos.component';

const routes: Routes = [
  {
    path: 'menu',
    component: MenuAdministracaoComponent,
  },
  {
    path: 'usuarios/lista',
    component: UsuariosListComponent,
    canActivate: [AclGuard],
    data: {
      permissions: [Permissao.ADM_USUARIOS_LISTA],
    },
  },
  {
    path: 'usuarios',
    component: UsuariosUpsertComponent,
    canActivate: [AclGuard],
    data: {
      permissions: [Permissao.ADM_USUARIOS_LISTA],
    },
  },
  {
    path: 'atualização-terminal',
    component: AtualizacaoTerminalComponent,
    canActivate: [AclGuard],
    data: {
      permissions: [Permissao.ADM_TERMINAIS_ATUALIZACAO],
    },
  },
  {
    path: 'processamento-cnab',
    component: PagamentosComponent,
    canActivate: [AclGuard],
    data: {
      permissions: [Permissao.ADM_PROCESSAMENTO_CNAB],
    },
  },
  {
    path: 'comissoes-padroes-lista',
    component: ComissoesPadroesListComponent,
    canActivate: [AclGuard],
    data: {
      permissions: [Permissao.ADM_COMISSAO_PADRAO],
    },
  },
  {
    path: 'comissoes-padroes',
    component: ComissoesPadroesUpsertComponent,
    canActivate: [AclGuard],
    data: {
      permissions: [Permissao.ADM_COMISSAO_PADRAO],
    },
  },
  {
    path: 'terminais-firmware',
    component: TerminaisFirmwareComponent,
    canActivate: [AclGuard],
    data: {
      permissions: [Permissao.ADM_TERMINAIS_FIRMWARE],
    },
  },
  {
    path: 'dias-de-faturamento',
    component: DiasFaturamentoComponent,
    canActivate: [AclGuard],
    data: {
      permissions: [Permissao.ADM_DIAS_FATURAMENTO_LISTA],
    },
  },
  {
    path: 'estornar-pix',
    component: EstornarPixComponent,
    canActivate: [AclGuard],
    data: {
      permissions: [Permissao.ADM_ESTORNO_PIX],
    },
  },
  {
    path: 'alterar-status-recarga',
    component: AlteraStatusRecargaComponent,
    canActivate: [AclGuard],
    data: {
      permissions: [Permissao.ADM_STATUS_RECARGA],
    },
  },
  {
    path: 'perfis',
    component: PerfisComponent,
    canActivate: [AclGuard],
    data: {
      permissions: [Permissao.ADM_PERFIS_PERMISSOES],
    },
  },
  {
    path: 'trocar-senha',
    component: TrocarSenhaComponent,
  },
  {
    path: 'mensagens-terminais',
    component: MensagensTerminaisComponent,
    canActivate: [AclGuard],
    data: {
      permissions: [Permissao.ADM_MENSAGEM_TERMINAL],
    },
  },
  {
    path: 'feriados',
    component: FeriadosComponent,
    canActivate: [AclGuard],
    data: {
      permissions: [Permissao.ADM_FERIADOS],
    },
  },
  {
    path: 'bancos-pdv',
    component: BancosPdvComponent,
    canActivate: [AclGuard],
    data: {
      permissions: [Permissao.ADM_BANCOS_PDV],
    },
  },
  {
    path: 'hipercap-produtos',
    component: HipercapProdutosComponent,
    canActivate: [AclGuard],
    data: {
      permissions: [Permissao.ADM_HIPERCAP],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdministracaoRoutingModule {}
